import { Objects }	from "ts-base/objects";

import * as common	from "@glas/shared/common";

export const getCategories = (categoryList:common.CategoryList):ReadonlyArray<common.Category> =>
	Objects.unsafeTypedKeys(categoryList);

export const getObjectDescriptionsForCategory = (categoryList:common.CategoryList, category:common.Category):ReadonlyArray<common.Objectdesc> =>
	Objects.unsafeTypedKeys(categoryList[category]);

export const getTypesForObjectDescription = (categoryList:common.CategoryList, objectdesc:common.Objectdesc):ReadonlyArray<common.Type> =>
	Object.values(categoryList).flatMap(it => it[objectdesc] ?? []);
