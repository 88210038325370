import { JSX }		from "preact";

import { Link }		from "@v3/preact/router/Link";

import * as common	from "@glas/shared/common";

export type ObjectThumbnailProps = Readonly<{
	thumbnails: ReadonlyArray<common.ObjectThumbnail>,
}>;

export const ObjectThumbnails = ({ thumbnails }:ObjectThumbnailProps):JSX.Element =>
	<div>
		<ul class="thumbnails">
			{ thumbnails.map((object:common.ObjectThumbnail) =>
				<li class="thumbnail-item">
					<Link href={"/object/" + window.encodeURIComponent(object.inventoryId)}>
						<div class="image-border thumbnail-image">
							<img
								class="image"
								alt={
									object.imageFile !== null
									?	`Foto von ${common.InventoryId.removeLocationPrefix(object.inventoryId)}`
									:	`Kein Foto für ${common.InventoryId.removeLocationPrefix(object.inventoryId)} verfügbar`
								}
								src={
									object.imageFile !== null
									?	"/img/180/" + window.encodeURIComponent(object.imageFile)
									:	"/img/NoImageFound_180.png"
								}
							/>
						</div>
						<div>
							<span class="thumbnail-title">{object.titleShort}</span>
							<span class="thumbnail-description">{object.datingShort}</span>
						</div>
					</Link>
				</li>
			)}
		</ul>
	</div>;
