import { JSX }					from "preact";

import { usePathname }			from "@v3/preact/hook/usePathname";

import { classes }				from "ts-base/web/dom";

import * as common				from "@glas/shared/common";

import { NavigationLink }		from "@glas/frontend/components/NavigationLink";
import { ScrollToTopButton }	from "@glas/frontend/components/ScrollToTopButton";

export type FooterProps = Readonly<{
	searchResults:	common.ExtendedSearchResults|null,
}>;

export const Footer = ({ searchResults }:FooterProps):JSX.Element => {
	const pathname	= usePathname();

	const footerStyle =
		pathname === "/search" && searchResults === null	? "searchempty"	:
		pathname === "/imprint"								? "imprint"		:
		pathname === "/"									? "start"		:
		"default";

	const footerClass = classes("footer", `footer-${footerStyle}`);

	return (
		<div class={footerClass}>
			<ScrollToTopButton/>
			<div class="footer-brightbar">
				<img class="footer-logo" alt="BmBF Logo" src="/img/bmbf-logo.svg"/>
			</div>
			<nav class="footer-darkbar">
				<ul class="footer-navigation">
					<li><NavigationLink href="/contact">Kontakt</NavigationLink></li>
					<li><NavigationLink href="/imprint">Impressum</NavigationLink></li>
				</ul>
			</nav>
		</div>
	);
};
