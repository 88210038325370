import { JSX }	from "preact";

import * as common	from "@glas/shared/common";

import {
	checkBox,
	rangeInputTwoDecimals,
	select,
} from "@glas/frontend/pages/search/searchInput";

export type AdvancedSearchProps = Readonly<{
	dropDownTexts:	common.DropDownTexts,
	searchOptions:	common.ExtendedSearchOptions,
	more:			boolean,
}>;

export const AdvancedSearch = ({ dropDownTexts, searchOptions, more }:AdvancedSearchProps):JSX.Element => {
	return (
		<div class={`search-more ${more ? "visible" : ""}`}>
			<div class="search-col">
				{ rangeInputTwoDecimals("Höhe",			"cm",	"heightFrom",	"heightTo",	searchOptions.heightFrom,	searchOptions.heightTo) }
				{ rangeInputTwoDecimals("Wandstärke",	"mm",	"widthFrom",	"widthTo",	searchOptions.widthFrom,	searchOptions.widthTo) }
				{ rangeInputTwoDecimals("Masse",		"g",	"weightFrom",	"weightTo",	searchOptions.weightFrom,	searchOptions.weightTo) }
				{ rangeInputTwoDecimals("Füllvolumen",	"ml",	"volumeFrom",	"volumeTo",	searchOptions.volumeFrom,	searchOptions.volumeTo) }
			</div>
			<div class="search-col">
				{ select("color",				searchOptions.color,				dropDownTexts.colors,				"Farbe") }
				{ select("fluorescenceColor",	searchOptions.fluorescenceColor,	dropDownTexts.fluorescenceColors,	"Fluoreszenz-Farbe") }
				{ checkBox("raman", searchOptions.raman, "Raman Spektroskopie verfügbar") }
				{ checkBox("uvvis", searchOptions.uvvis, "UV-Vis verfügbar") }
			</div>
		</div>
	);
};
