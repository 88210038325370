import { JSX }		from "preact";

import { Link }		from "@v3/preact/router/Link";

import * as common	from "@glas/shared/common";

export type ExhibitionBoardThumbnailsProps = Readonly<{
	thumbnails: ReadonlyArray<common.ExhibitionBoardThumbnail>,
}>;

export const ExhibitionBoardThumbnails = ({ thumbnails }:ExhibitionBoardThumbnailsProps):JSX.Element =>
	<ul class="thumbnails">
		{ thumbnails.map((thumbnail:common.ExhibitionBoardThumbnail) =>
			<li class="thumbnail-item">
				<Link href={"/exhibitionboard/" + window.encodeURIComponent(thumbnail.boardId)}>
					<div class="image-border thumbnail-image">
						<img alt={thumbnail.titleShort} src={"/img/exhibition/180/" + window.encodeURIComponent(thumbnail.image)} class="image"/>
					</div>
					<div>
						<span class="thumbnail-description">{thumbnail.titleShort}</span>
					</div>
				</Link>
			</li>
		)}
	</ul>;
