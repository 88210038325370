import { JSX }				from "preact";

import * as common			from "@glas/shared/common";

import { ObjectThumbnails }	from "@glas/frontend/components/ObjectThumbnails";
import { Pagination }		from "@glas/frontend/components/Pagination";
import * as actions			from "@glas/frontend/actions";

export type ResultsProps = Readonly<{
	searchResults:	common.ExtendedSearchResults,
}>;

export const Results = ({ searchResults }:ResultsProps):JSX.Element =>
	<div class="search-results">
		<Pagination
			searchResults={searchResults}
			searchAction={actions.getSearchResult}
		/>
		<ObjectThumbnails thumbnails={searchResults.data}/>
		{
			searchResults.data.length > 0 &&
			<Pagination
				searchResults={searchResults}
				searchAction={actions.getSearchResult}
			/>
		}
	</div>;
