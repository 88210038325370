import { JSX }					from "preact";

import { Router }				from "@v3/preact/router/Router";
import { Route }				from "@v3/preact/router/Route";

import {
	CollectionId,
	ExhibitionBoardId,
	InventoryId,
}	from "@glas/shared/common";

import * as actions				from "@glas/frontend/actions";
import { Model }				from "@glas/frontend/model";

import { Header }				from "@glas/frontend/components/Header";
import { Footer }				from "@glas/frontend/components/Footer";

import { CollectionOverview }	from "@glas/frontend/pages/collection/CollectionOverview";
import { CollectionDetail }		from "@glas/frontend/pages/collection/CollectionDetail";
import { Contact }				from "@glas/frontend/pages/contact/Contact";
import { ExhibitionOverview }	from "@glas/frontend/pages/exhibition/ExhibitionOverview";
import { ExhibitionBoard }		from "@glas/frontend/pages/exhibition/ExhibitionBoard";
import { Imprint }				from "@glas/frontend/pages/imprint/Imprint";
import { Introduction }			from "@glas/frontend/pages/introduction/Introduction";
import { ObjectOverview }		from "@glas/frontend/pages/object/ObjectOverview";
import { ObjectDetail }			from "@glas/frontend/pages/object/ObjectDetail";
import { Search }				from "@glas/frontend/pages/search/Search";
import { Start }				from "@glas/frontend/pages/start/Start";
import { NotFound }				from "@glas/frontend/pages/notFound/NotFound";

export type AppProps = Readonly<{
	model:Model,
}>;

export const App = ({ model }:AppProps):JSX.Element => {
	return (
		<>
			<Header/>
			<div class="main">
				<Router
					routes={[
						Route.path("")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:	<Start/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.path("introduction")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:	<Introduction introduction={model.introduction}/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.path("collections")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:	<CollectionOverview	collections={model.collections}/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.path("collection", CollectionId)({
							enabled:	true,
							content:	([ collectionId ], _searchParams) => ({
								component:	<CollectionDetail collection={model.collection}/>,
								loader:		() => {
									scrollToTop();
									actions.getExtendedCollection(collectionId);
								},
								searcher:	null,
							}),
						}),
						Route.path("objects")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:	<ObjectOverview objects={model.objects}/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.path("object", InventoryId)({
							enabled:	true,
							content:	([ inventoryId ], _searchParams) => ({
								component:	<ObjectDetail inventoryId={inventoryId} obj={model.object}/>,
								loader:		() => {
									scrollToTop();
									actions.getExtendedObject(inventoryId);
								},
								searcher:	null,
							}),
						}),
						// should be exhibitionboards, but not everything in this world can be consistent :)
						Route.path("exhibition")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:	<ExhibitionOverview	exhibition={model.exhibition}/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.path("exhibitionboard", ExhibitionBoardId)({
							enabled:	true,
							content:	([ boardId ], _searchParams) => ({
								component:	<ExhibitionBoard exhibitionBoard={model.exhibitionBoard}/>,
								loader:		() => {
									scrollToTop();
									actions.getExtendedExhibitionBoard(boardId);
								},
								searcher:	null,
							}),
						}),
						Route.path("search")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:
									<Search
										dropDownTexts={model.dropDownTexts}
										searchResults={model.searchResults}
										searchOptions={model.searchOptions}
									/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.path("contact")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:	<Contact/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.path("imprint")({
							enabled:	true,
							content:	(_pathParams, _searchParams) => ({
								component:	<Imprint/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
						Route.fallback("notFound")({
							enabled:	true,
							content:	(_searchParams) => ({
								component:	<NotFound/>,
								loader:		scrollToTop,
								searcher:	null,
							}),
						}),
					]}
				/>
			</div>
			<Footer searchResults={model.searchResults}/>
		</>
	);
};

// hack: scroll page to top when changed
const scrollToTop = ():void => {
	window.scrollTo({ top: 0 });
};
