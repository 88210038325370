import { JSX }	from "preact";

import * as common	from "@glas/shared/common";

import * as actions from "@glas/frontend/actions";

export type PaginationProps = Readonly<{
	searchResults:	common.ExtendedSearchResults,
	searchAction:	(searchOptions:common.ExtendedSearchOptions) => void,
}>;

export const Pagination = ({ searchResults, searchAction }:PaginationProps):JSX.Element => {
	// How many page links to show in the middle of lots of results
	const siblings:number		= 2;
	const shownPages:number		= (siblings * 2) + 1;
	const resultPages:number	= searchResults.resultPages;
	const currentPage:number	= searchResults.searchOptions.currentResultPage;

	const handleClick = (page:number, ev:Event) => {
		ev.preventDefault();
		actions.setCurrentResultPage(page);
		searchAction({
			...searchResults.searchOptions,
			currentResultPage: page,
		});
	};

	// Pagination shows first and last page and five pages in between (2 siblings before and after currentResultPage):
	// 1 ... 3 4 [5] 6 7 ... 39
	// this function returns the first of these 5 pages
	const getFirstPage = (resultPages:number):number => {
		const firstPage:number	= currentPage - siblings > 1 ? currentPage - siblings : 1;
		const lastPage:number	= firstPage + (siblings * 2);

		return (
			(resultPages <= shownPages + 1 + 1 || currentPage === 1 + siblings + 1)	?
				1
				:
			currentPage === resultPages - siblings - 1	?
				resultPages - (siblings * 2)
				:
			lastPage <= resultPages ?
				firstPage
				:
			// else
				firstPage - (lastPage - resultPages)
		);
	};

	const firstPage = getFirstPage(resultPages);

	return (
		<div class="pagination">
			<div class="pagination-pages">
				{ firstPage > 1 + 1 &&
					<span>
						<button
							class={`pagination-button ${(currentPage === 1) ? "active" : ""}`}
							onClick={(ev:Event) => handleClick(1, ev)}>1</button>
						<span class="pagination-spacer">...</span>
					</span>
				}
				{ Array(resultPages <= (shownPages + 1 + 1) ? resultPages : shownPages).fill(0).map((_, index) =>
					<button
						class={`pagination-button ${(currentPage === index + firstPage) ? "active" : ""}`}
						onClick={(ev) => handleClick(index + firstPage, ev)}
					>
						{index + firstPage}
					</button>
				)}
				{ firstPage < (resultPages - shownPages) && resultPages > (shownPages + 1 + 1) &&
					<span>
						<span class="pagination-spacer">...</span>
						<button
							class={`pagination-button ${(currentPage === resultPages) ? "active" : ""}`}
							onClick={(ev) => handleClick(resultPages, ev)}
						>
							{resultPages}
						</button>
					</span>
				}
			</div>
			<span class="pagination-results">
				{
					`${(searchResults.results > 0 ? searchResults.offset + 1 : 0)} - ${(searchResults.offset + searchResults.data.length)} von ${searchResults.results} Treffern`
				}
			</span>
		</div>
	);
};
