import { JSX }			from "preact";
import { useState }		from "preact/hooks";

import { Objects }		from "ts-base/objects";
import { Arrays }		from "ts-base/arrays";

import * as common		from "@glas/shared/common";

import { ParsedText }	from "@glas/frontend/components/ParsedText";

export type ObjectDetailProps = Readonly<{
	inventoryId:	common.InventoryId,
	obj:			common.ExtendedObject|null|"loading",
}>;

export const ObjectDetail = ({ inventoryId, obj }:ObjectDetailProps):JSX.Element => {
	const loaded	= obj === "loading" ? null : obj;

	type Fullsize = FullsizeImage | FullsizeSpektrum;
	type FullsizeImage = {
		src:			string,
		alt:			string,
		srcFullsize:	string,
	};
	type FullsizeSpektrum = FullsizeImage & {
		altASCII:		string,
		srcASCII:		string,
	};

	const convertImages = (images:ReadonlyArray<string>, path:string):ReadonlyArray<FullsizeImage> =>
		images.filter((image:string) => image !== "").map((image:string, index:number) => ({
			alt:			`Foto ${index + 1} von Objekt ${inventoryId}`,
			src:			path + (index === 0 ? "355/" : "180/") + window.encodeURIComponent(image),
			srcFullsize:	path + window.encodeURIComponent(image),
		}));
	const convertSpektrum = (type:string, images:ReadonlyArray<string>, path:string):ReadonlyArray<FullsizeSpektrum> =>
		images.filter((image:string) => image !== "").map((image:string, index:number) => ({
			alt:			`${type} ${index + 1} von Objekt ${inventoryId}`,
			src:			path + "120/" + window.encodeURIComponent(image),
			srcFullsize:	path + window.encodeURIComponent(image),
			altASCII:		`ASCII-Datei zu ${type} ${index + 1} von Objekt ${inventoryId}`,
			srcASCII:		image.slice(0, -4) + ".txt",
		})
	);

	const imageSources	=
		loaded !== null ? loaded : {
			images:			[],
			ramanImages:	[],
			uvvisImages:	[],
		};

	const images:ReadonlyArray<FullsizeImage> =
		convertImages(imageSources.images, "/img/");

	const ramanImages:ReadonlyArray<FullsizeSpektrum> =
		convertSpektrum("Raman-Spektrum", imageSources.ramanImages, "/img/raman/");

	const uvvisImages:ReadonlyArray<FullsizeSpektrum> =
		convertSpektrum("Uvvis-Spektrum", imageSources.uvvisImages, "/img/uvvis/");

	const allImages:ReadonlyArray<Fullsize> = Arrays.concat(images, Arrays.concat(ramanImages, uvvisImages));

	const [ fullsize, setFullsize ]		= useState<Fullsize|null>(null);

	const handleClick = (newFullsize:Fullsize|null):void =>
		setFullsize(
			newFullsize !== null && (fullsize === null || newFullsize.src !== fullsize.src)
			?	newFullsize
			:	null
		);

	const createPropertyList = (obj:common.ExtendedObject):JSX.Element =>
		<ul class="object-properties">
			{
				Objects.unsafeTypedEntries(propertyList).map(([ key, label ]) => {
					const value:string|null = obj[key];

					return (
						value !== null
						?	<li class="object-properties-item">
								<span class="object-properties-item-title">{label}:</span>
								<ParsedText text={value}/>
							</li>
						:	""
					);
				})
			}
		</ul>;

	const getImage = (image:FullsizeImage, index:number):JSX.Element => {
		const cssClass:string = index === 0 ? "image object-image-main" : "object-image-thumbnail";
		return (
			index === 0
			?	<button class="object-button-large" onClick={() => handleClick(image)}>
					<img class={cssClass} src={image.src} alt={image.alt}/>
					<span class="fa fa-search"></span>
				</button>
			:	<img class={cssClass} src={image.src} alt={image.alt} onClick={() => handleClick(image)}/>
		);

	};

	const getSpectrumImage = (image:FullsizeSpektrum):JSX.Element =>
		<div class="object-spektrum-item">
			<button onClick={() => handleClick(image)}>
				<img class="object-spektrum-image" src={image.src} alt={image.alt}/>
			</button>
			<a href={image.srcASCII} download={image.srcASCII.slice(2)} target="_blank">
				<img class="ascii" src="/img/ascii.svg" alt={image.altASCII}/>
			</a>
		</div>;

	const getFullsizeImage = ():JSX.Element|"" => {
		if (fullsize === null)	return "";

		const index:number = allImages.findIndex((elem) => elem.src === fullsize.src);

		return (
			<div class="fullsize">
				<button
					class={`fa fa-angle-left navigation ${index > 0 ? "" : "disabled"}`}
					disabled={index <= 0}
					onClick={() => handleClick(allImages[index - 1])}
				/>
				<div class="object-images image-border">
					<button class="fa fa-close close" onClick={() => handleClick(null)}>
						<img
							class="image object-image-main"
							src={fullsize.srcFullsize}
							alt={fullsize.alt}
						/>
					</button>
				</div>
				<button
					class={`fa fa-angle-right navigation ${index < allImages.length - 1 ? "" : "disabled"}`}
					disabled={index >= allImages.length - 1}
					onClick={() => handleClick(allImages[index + 1])}
				/>
			</div>
		);
	};

	return (
		obj === "loading"	? <div class="object-detail">Das Objekt wird geladen.</div>						:
		obj === null		? <div class="object-detail">Das angefragte Objekt ist nicht verfügbar.</div>	:
		<div class="object-detail">
			<div class="object-image-column">
				<div class="object-images image-border">
					{
						images.length > 0
						?	getImage(images[0], 0)
						:	<img
								class="image object-image-main no-image"
								alt={`Kein Foto für ${common.InventoryId.removeLocationPrefix(obj.inventoryId)} verfügbar`}
								src="/img/NoImageFound_355.png"
							/>
					}
					{ images.length > 1 &&
						<div class="object-image-thumbnails">
							{ images.map((image:FullsizeImage, index:number) =>
								index !== 0 ? getImage(image, index) : null)
							}
						</div>
					}
				</div>
				<div class="object-spektra">
					{ ramanImages.length > 0 &&
						<div>
							<h2>Raman-Spektren</h2>
							<div class="object-spektrum-items">
								{ ramanImages.map((image:FullsizeSpektrum) =>
									getSpectrumImage(image))}
							</div>
						</div>
					}
					{ uvvisImages.length > 0 &&
						<div>
							<h2>Uvvis-Spektren</h2>
							<div class="object-spektrum-items">
								{ uvvisImages.map((image:FullsizeSpektrum) =>
									getSpectrumImage(image))}
							</div>
						</div>
					}
				</div>
			</div>
			{getFullsizeImage()}
			<div class="object-summary">
				<h1>{obj.title}</h1>
				<span class="object-subtitle">
					{ `${obj.institution} (${inventoryId})` }
				</span>
				{createPropertyList(obj)}
				{ obj.description !== "" &&
					<div>
						<h2>Beschreibung und Einordnung</h2>
						<ParsedText text={obj.description}/>
					</div>
				}
				{ obj.measurements !== null &&
					<div>
						<h2>Maße</h2>
						<ParsedText text={obj.measurements}/>
					</div>
				}
				<div class="object-description">
					<p class="object-description-by">beschrieben von {obj.descriptionBy},</p>
					<p>{obj.descriptionDate}</p>
				</div>
			</div>
		</div>
	);
};

const propertyList	= {
	setDesc:			"Zusammengehörigkeit",
	relatedTo:			"Set",
	material:			"Material",
	condition:			"Zustand",
	placeOfManufacture:	"Herstellungsort",
	persons:			"Beteiligte Person(en)",
	period:				"Zeitraum",
	chemicalGlassType:	"Chemischer Glastyp",
	color:				"Farbe",
	fluorescenceColor:	"Fluoreszenz-Farbe",
	munsell:			"Munsell",
	restaurations:		"Restaurierungen",
} as const;
