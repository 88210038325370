import { JSX }				from "preact";

import * as common			from "@glas/shared/common";

import { ObjectThumbnails }	from "@glas/frontend/components/ObjectThumbnails";
import { ParsedText }		from "@glas/frontend/components/ParsedText";

export type CollectionDetailProps = Readonly<{
	collection:	common.ExtendedCollection|null|"loading",
}>;

export const CollectionDetail = ({ collection }:CollectionDetailProps):JSX.Element =>
	collection === "loading"	? <div class="collection-detail">Die Kollektion wird geladen.</div>	:
	collection === null			? <div class="collection-detail">Die angefragte Kollektion ist nicht verfügbar.</div>	:
	<div class="collection-detail">
		<h1>{collection.title}</h1>
		<span class="collection-description adoptFormat">
			<ParsedText text={collection.descShort}/>
		</span>
		{ collection.objectThumbnails.length === 0 ||
			<div class="collection-objects">
				<h2>Teile der Kollektion</h2>
				<ObjectThumbnails thumbnails={collection.objectThumbnails}/>
			</div>
		}
		<span class="collection-description adoptFormat">
			<ParsedText text={collection.description}/>
		</span>
	</div>;
