import { JSX }							from "preact";
import { useEffect, useState, useRef }	from "preact/hooks";

export const ScrollToTopButton = ():JSX.Element => {
	const position = useRef(0);
	const [ lastPosition, setLastPosition ] = useState(0);
	const [ visible, setVisible ] = useState(false);

	useEffect(
		() => {
			const handleScroll = () => {
				const currentPosition = window.pageYOffset;
				setVisible(currentPosition !== 0 && currentPosition < lastPosition);
				setLastPosition(currentPosition);
				position.current = currentPosition;
			};

			window.addEventListener("scroll", handleScroll, { passive: true });
			return () => window.removeEventListener("scroll", handleScroll);
		},
		[ lastPosition ]
	);

	return (
		<div class="top-button-wrapper">
		<button
			type="button"
			id="top-button"
			class={`top-button js-top-button arrowbutton ${visible ? "fadeIn" : ""}`}
			onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
		>
			<span class="fa fa-chevron-up"></span>
			<span class="sr-only">Nach oben</span>
		</button>
		</div>
	);
};
