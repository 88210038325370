import { JSX }				from "preact";

import * as common			from "@glas/shared/common";

import { ObjectThumbnails }	from "@glas/frontend/components/ObjectThumbnails";
import { ParsedText }		from "@glas/frontend/components/ParsedText";

export type ExhibitionBoardProps = Readonly<{
	exhibitionBoard:	common.ExtendedExhibitionBoard|null|"loading",
}>;

export const ExhibitionBoard = ({ exhibitionBoard }:ExhibitionBoardProps):JSX.Element =>
	exhibitionBoard === "loading"	? <div class="exhibition-detail">Die Ausstellungstafel wird geladen.</div>	:
	exhibitionBoard === null		? <div class="exhibition-detail">Die angefragte Ausstellungstafel ist nicht verfügbar.</div>	:
	<div class="exhibition-detail">
		<h1>{exhibitionBoard.title}</h1>
		<div class="exhibition-description adoptFormat">
			<ParsedText text={exhibitionBoard.text}/>
			<a class="link-to-pdf" href={`/pdf/${window.encodeURIComponent(exhibitionBoard.pdfFile)}`} target="_blank">
				<span>Tafel als PDF-Datei</span>
			</a>
		</div>
		{ exhibitionBoard.objectThumbnails.length !== 0 &&
			<div>
				<h2>Objekte</h2>
				<ObjectThumbnails thumbnails={exhibitionBoard.objectThumbnails}/>
			</div> }
	</div>;
