import { JSX }				from "preact";

import { usePathname }		from "@v3/preact/hook/usePathname";

import { NavigationLink }	from "@glas/frontend/components/NavigationLink";

export type HeaderProps = Readonly<{}>;

export const Header = ({}:HeaderProps):JSX.Element => {
	const pathname	= usePathname();

	const stoerer	= pathname === "/";

	return (
		<div class="header">
			<div class="header-logo">
				<NavigationLink href="/">
					<img alt="Glas Logo" src="/img/LogoGlas.png"/>
				</NavigationLink>
			</div>
			<div class="header-main">
				<div class="header-main-title">
					<span class="header-main-title">Material, Funktion und Bedeutung</span>
					<span class="header-main-subtitle">ein Forschungsprojekt der Universität Gießen</span>
				</div>
				<div class="header-main-navigation">
					<nav>
						<ul class="header-main-navigation-items">
							{ stoerer ||
								<li class="exhibition">
									<NavigationLink href="/exhibition">Ausstellung</NavigationLink>
								</li>
							}
							<li>
								<NavigationLink href="/introduction">Projekt</NavigationLink>
							</li>
							<li>
								<NavigationLink href="/collections">Kollektionen</NavigationLink>
							</li>
							<li>
								<NavigationLink href="/objects">Objekte</NavigationLink>
							</li>
							<li>
								<NavigationLink href="/search">Objektsuche</NavigationLink>
							</li>
						</ul>
					</nav>
				</div>
				{ stoerer &&
					<div class="exhibition-prime">
						<NavigationLink href="/exhibition">
							<img alt="Ausstellungen" src="img/Zitrone.png" class="exhibition-prime-image"/>
							<div class="exhibition-prime-bg-cover"></div>
							<div class="exhibition-prime-bg">
								<span class="exhibition-prime-text">Das Glas der <br/> Schwarzburger<br/></span>
								<span class="exhibition-prime-subtext">- Die Ausstellung -</span>
							</div>
						</NavigationLink>
					</div>
				}
			</div>
		</div>
	);
};
