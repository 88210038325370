import { render }	from "preact";

import { Endo }		from "ts-base/endo";

import { App }		from "@glas/frontend/App";
import { Model }	from "@glas/frontend/model";
import * as actions	from "@glas/frontend/actions";

window.addEventListener("DOMContentLoaded", () => {
	let model = Model.initial;

	const display	= () =>
		render(<App model={model}/>, document.body);

	display();

	actions.setRefresh(
		(change:Endo<Model>):void	=> {
			model = change(model);
			display();
		}
	);

	actions.boot();
});
