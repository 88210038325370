import { JSX }		from "preact";

import { Link }		from "@v3/preact/router/Link";

import * as common	from "@glas/shared/common";

export type CollectionOverviewProps = Readonly<{
	collections:	ReadonlyArray<common.Collection>|null,
}>;

export const CollectionOverview = ({ collections }:CollectionOverviewProps):JSX.Element =>
	<div class="collection-overview">
		<h1>Kollektionen</h1>
		{
			collections !== null
			?	<ul class="collection-items">
					{ collections.map((collection:common.Collection) =>
						<li class="collection-item">
							<Link href={"/collection/" + window.encodeURIComponent(collection.id)}>
								<div class="image-border collection-item-image">
									<img alt={collection.title} src={"img/355/" + window.encodeURIComponent(collection.image)} class="image"/>
								</div>
								<span class="collection-item-title">{collection.title}</span>
								<span class="collection-item-subtitle">{collection.subtitle}</span>
							</Link>
						</li>
					)}
				</ul>
			:	<div>Leider sind derzeit keine Kollektionen verfügbar.</div>
		}
	</div>;
