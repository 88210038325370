import { JSX }	from "preact";

export type ContactProps = Readonly<{}>;

export const Contact = ({}:ContactProps):JSX.Element =>
	<div class="contact">
		<h1>Kontakt</h1>

		<ul>
			<li>
				<div class="contact-item">
					<div class="contact-logo">
						<img class="contact-logo-img" src="img/LogoArnstadt_03.svg"/>
					</div>
					<div class="contact-data">
						<h2>Schlossmuseum Arnstadt</h2>
						<div class="contact-address">
							<div>
								Schloßplatz 1<br/>
								99310 Arnstadt
							</div>
							<div>
								<span>Telefon:</span>
								<span>+49 (0)36 28 / 60 29 32</span>
							</div>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="contact-item">
					<div class="contact-logo">
						<img class="contact-logo-img" src="img/LogoSondershausen.svg"/>
					</div>
					<div class="contact-data">
						<h2>Schlossmuseum Sondershausen</h2>
						<div class="contact-address">
							<div>
								Schloss 1<br/>
								99706 Sondershausen
							</div>
							<div>
								<span>Telefon:</span>
								<span>+49 (0)36 32 / 62 24 20</span>
							</div>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="contact-item">
					<div class="contact-logo">
						<img class="contact-logo-img" src="img/LogoRudolstadt.jpg"/>
					</div>
					<div class="contact-data">
						<h2>Thüringer Landesmuseum Heidecksburg</h2>
						<div class="contact-address">
							<div>
								Schlossbezirk 1<br/>
								07407 Rudolstadt
							</div>
							<div>
								<span>Telefon:</span>
								<span>+49 (0)36 72 / 42 90 22</span>
							</div>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="contact-item">
					<div class="contact-logo">
						<img class="contact-logo-img" src="img/LogoBadFrankenhausen.jpg"/>
					</div>
					<div class="contact-data">
						<h2>Regionalmuseum im Schloss zu Bad Frankenhausen</h2>
						<div class="contact-address">
							<div>
								Schloßstraße 13<br/>
								06567 Bad Frankenhausen
							</div>
							<div>
								<span>Telefon:</span>
								<span>+49 (0)3 46 71 / 6 20 86</span>
							</div>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="contact-item">
					<div class="contact-logo">
						<img class="contact-logo-img" src="img/LogoFreiberg_02.svg"/>
					</div>
					<div class="contact-data">
						<h2>Technische Universität Bergakademie Freiberg</h2>
						<div class="contact-address">
							<div>
								Akademiestraße 6<br/>
								09599 Freiberg
							</div>
							<div>
								<span>Telefon:</span>
								<span>+49 (0)3 731/39 0</span>
							</div>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="contact-item">
					<div class="contact-logo">
						<img class="contact-logo-img" src="img/LogoJLU_04.svg"/>
					</div>
					<div class="contact-data">
						<h2>Justus-Liebig-Universität Gießen</h2>
						<div class="contact-address">
							<div>
								Historisches Institut<br/>
								Justus-Liebig-Universität Gießen<br/>
								z.H. Frau Dr. Annette C. Cremer<br/>
								Otto-Behaghel-Str. 10 G [Raum 31]<br/>
								35394 Gießen
							</div>
							<div>
								<span>Telefon:</span>
								<span>+49 (0)6 41 / 99 28 003</span>
							</div>
							<div>
								<span>E-Mail:</span>
								<span>annette.cremer@geschichte.uni-giessen.de</span>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>;
