import { JSX }	from "preact";

import * as common	from "@glas/shared/common";

import { ObjectThumbnails }		from "@glas/frontend/components/ObjectThumbnails";
import { Pagination }			from "@glas/frontend/components/Pagination";
import * as actions				from "@glas/frontend/actions";

export type ObjectOverviewProps = Readonly<{
	objects:	common.ExtendedSearchResults|null,
}>;

export const ObjectOverview = ({ objects }:ObjectOverviewProps):JSX.Element =>
	<div class="object-overview">
		<h1>Objekte</h1>
		{
			objects !== null
			?	<div>
					<Pagination
						searchResults={objects}
						searchAction={actions.getObjects}
					/>
					<ObjectThumbnails thumbnails={objects.data}/>
					<Pagination
						searchResults={objects}
						searchAction={actions.getObjects}
					/>
				</div>
			:	<div>Leider sind derzeit keine Objekte verfügbar.</div>
		}
	</div>;
