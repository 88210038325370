import { JSX }			from "preact";
import { useState }		from "preact/hooks";

import * as common		from "@glas/shared/common";

import * as actions		from "@glas/frontend/actions";
import * as searchUtil	from "@glas/frontend/pages/search/searchUtil";

import {
	getCategories,
	getObjectDescriptionsForCategory,
	getTypesForObjectDescription,
}	from "@glas/frontend/util";

import {
	rangeInputFourDigits,
	select,
	textInput,
} from "@glas/frontend/pages/search/searchInput";

export type MainSearchProps = Readonly<{
	dropDownTexts:	common.DropDownTexts,
	searchOptions:	common.ExtendedSearchOptions,
}>;

export const MainSearch = ({ dropDownTexts, searchOptions }:MainSearchProps):JSX.Element => {
	const [ objectDescs, setObjectDescs ]	= useState<ReadonlyArray<string>>([]);
	const [ types, setTypes ]				= useState<ReadonlyArray<string>>([]);

	return (
		<div class="search-main">
			<div class="search-col">
				{ textInput("Volltextsuche", "item", searchOptions.item) }
				{ select("institution",	searchOptions.institution,	dropDownTexts.institutions,	"Institution") }
				{ select("locations",	searchOptions.locations,	dropDownTexts.locations,	"Herstellungsort") }
				{ rangeInputFourDigits("Datierung", "", "datingFrom", "datingTo", searchOptions.datingFrom, searchOptions.datingTo) }
			</div>
			<div class="search-col">
				{
					categorySelect(
						"category",
						searchOptions.category,
						getCategories(dropDownTexts.categories),
						"Sachgruppe",
						(newValue:string) => {
							// TODO factor could be a function SearchOptions => string => SearchOptions
							actions.setSearchOption("category",		searchUtil.parseStringOrNull(newValue));
							actions.setSearchOption("objectDesc",	null);
							actions.setSearchOption("type",			null);
							setObjectDescs(
								newValue === "" ? [] :
								getObjectDescriptionsForCategory(dropDownTexts.categories, common.Category(newValue))
							);
							setTypes([]);
						}
					)
				}
				{
					categorySelect(
						"objectDesc",
						searchOptions.objectDesc,
						objectDescs,
						"Objektbezeichnung",
						(newValue:string) => {
							// TODO factor could be a function SearchOptions => string => SearchOptions
							actions.setSearchOption("objectDesc",	searchUtil.parseStringOrNull(newValue));
							actions.setSearchOption("type",			null);
							setTypes(
								newValue === "" ? [] :
								getTypesForObjectDescription(dropDownTexts.categories, common.Objectdesc(newValue))
							);
						}
					)
				}
				{
					categorySelect(
						"type",
						searchOptions.type,
						types,
						"Typ",
						(newValue:string) => {
							actions.setSearchOption("type",			searchUtil.parseStringOrNull(newValue));
						}
					)
				}
				{
					select(
						"chemicalGlassType",
						searchOptions.chemicalGlassType,
						dropDownTexts.chemicalGlasTypes,
						"Chemischer Glastyp"
					)
				}
			</div>
		</div>
	);
};

const categorySelect = (name:keyof common.SearchOptions, value:string|null, texts:ReadonlyArray<string>, caption:string, func:(value:string) => void):JSX.Element =>
	<div class="search-form-unit">
		<label class="search-form-label search-form-unit-col1" for={name}>{caption}</label>
		<div class="search-form-unit-col2">
			<select
				id={name}
				class="search-form-input select"
				name={name}
				value={value ?? ""}
				disabled={texts.length === 0}
				onChange={(ev) => func(ev.currentTarget.value)}
			>
				<option></option>
				{ texts.map((text:string) =>
					<option value={text}>{text}</option>
				)}
			</select>
			<div class="search-form-unit-col3"></div>
		</div>
	</div>;
