import * as common	from "@glas/shared/common";

export type Model	= Readonly<{
	dropDownTexts:		common.DropDownTexts|null,
	introduction:		ReadonlyArray<common.IntroductionParagraph>|null,
	object:				common.ExtendedObject|null|"loading",
	objects:			common.ExtendedSearchResults|null,
	collection:			common.ExtendedCollection|null|"loading",
	collections:		ReadonlyArray<common.Collection>|null,
	exhibition:			common.ExtendedExhibition|null,
	exhibitionBoard:	common.ExtendedExhibitionBoard|null|"loading",
	searchOptions:		common.ExtendedSearchOptions,
	searchResults:		common.ExtendedSearchResults|null,
}>;

export namespace Model {
	export const initial:Model	= {
		dropDownTexts:		null,
		introduction:		null,
		object:				null,
		objects:			null,
		collection:			null,
		collections:		null,
		exhibition:			null,
		exhibitionBoard:	null,
		searchOptions:		common.ExtendedSearchOptions.empty,
		searchResults:		null,
	};
}
