import { JSX }			from "preact";

import { Fn }			from "ts-base/fn";

import * as common		from "@glas/shared/common";
import * as primitive	from "@glas/shared/primitive";

import * as actions		from "@glas/frontend/actions";
import * as searchUtil	from "@glas/frontend/pages/search/searchUtil";

export const checkBox = (name:keyof common.SearchOptions, value:boolean|null, text:string):JSX.Element =>
	<div class="search-form-unit">
		<div class="search-form-label search-form-unit-col1"></div>
		<div class="search-form-unit-col2 la">
			<input
				id={name}
				class="search-form-input checkbox"
				type="checkbox"
				name={name}
				value={value !== null ? value.toString() : "false"}
				checked={value ?? false}
				onChange={() =>
					actions.setSearchOption(name, searchUtil.invertBooleanOrNull(value))
				}
			/>
			<label class="search-form-label checkbox" for={name}>{text}</label>
			<div class="search-form-unit-col3"></div>
		</div>
	</div>;

export const select = (name:keyof common.SearchOptions, value:string|null, texts:ReadonlyArray<string>, caption:string):JSX.Element =>
	<div class="search-form-unit">
		<label class="search-form-label search-form-unit-col1" for={name}>{caption}</label>
		<div class="search-form-unit-col2">
			<select
				id={name}
				class="search-form-input text"
				name={name}
				value={value ?? ""}
				onChange={(ev) =>
					actions.setSearchOption(name, searchUtil.parseStringOrNull(ev.currentTarget.value))
				}
			>
				<option></option>
				{ texts.map(text =>
					<option value={text}>{text}</option>
				)}
			</select>
			<div class="search-form-unit-col3"></div>
		</div>
	</div>;

const rangeInput = (
	regex:string,
	text:string,
	func:Fn<string, number|null>
) => (
	caption:string,
	unity:string,
	name1:keyof common.SearchOptions,
	name2:keyof common.SearchOptions,
	value1:number|null,
	value2:number|null
):JSX.Element =>
	<div class="search-form-unit">
		<label class="search-form-label search-form-unit-col1" for={name1}>{caption}</label>
		<div class="search-form-input search-form-unit-col2">
			<div class="search-form-input">
				<label class="search-form-label" for={name1}>von</label>
				{ numberInput(name1, value1, regex, text, func) }
				<label class="search-form-label" for={name2}>bis</label>
				{ numberInput(name2, value2, regex, text, func) }
			</div>
			<div class="search-form-unit-col3">{unity}</div>
		</div>
	</div>;

export const rangeInputTwoDecimals =
	rangeInput(
		primitive.regexTwoDecimalFloatComma.source,
		"Bitte geben Sie einen Wert ohne führende Null und mit maximal zwei Nachkommastellen ein",
		searchUtil.parseFloatOrNull
	);

export const rangeInputFourDigits =
	rangeInput(
		primitive.regexFourDigitNumber.source,
		"Bitte geben Sie eine vierstellige Jahreszahl ein",
		searchUtil.parseIntOrNull
	);

const numberInput = (
	name:keyof common.SearchOptions,
	value:number|null,
	pattern:string,
	title:string,
	func:Fn<string, number|null>
):JSX.Element =>
	<input
		id={name}
		class="search-form-input number"
		type="text"
		name={name}
		pattern={pattern}
		title={title}
		value={value !== null ? value.toString().replace(".", ",") : ""}
		onChange={(ev) =>
			actions.setSearchOption(name, func(ev.currentTarget.value))
		}
	/>;

export const textInput = (caption:string, name:keyof common.SearchOptions, value:string|null):JSX.Element =>
	<div class="search-form-unit">
		<label class="search-form-label search-form-unit-col1" for="item">{caption}</label>
		<div class="search-form-unit-col2">
			<input
				id={name}
				class="search-form-input text"
				type="text"
				name={name}
				value={value ?? ""}
				onChange={(ev) =>
					actions.setSearchOption(name, searchUtil.parseStringOrNull(ev.currentTarget.value))
				}
			/>
			<div class="search-form-unit-col3"></div>
		</div>
	</div>;
