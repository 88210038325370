import { JSX }				from "preact";
import { useState }			from "preact/hooks";

import * as common			from "@glas/shared/common";

import * as actions			from "@glas/frontend/actions";
import { MainSearch }		from "@glas/frontend/pages/search/MainSearch";
import { AdvancedSearch }	from "@glas/frontend/pages/search/AdvancedSearch";
import { Results }			from "@glas/frontend/pages/search/Results";

export type SearchProps = Readonly<{
	dropDownTexts:	common.DropDownTexts|null,
	searchOptions:	common.ExtendedSearchOptions,
	searchResults:	common.ExtendedSearchResults|null,
}>;

export const Search = ({ dropDownTexts, searchOptions, searchResults }:SearchProps):JSX.Element => {
	const [ moreButton, setMoreButton ] = useState(false);

	const handleSubmit = (ev:Event) => {
		ev.preventDefault();
		actions.getSearchResult({ ...searchOptions, currentResultPage: 1 });
	};

	return (
		<div class="search">
			<h1>Objektsuche</h1>

			{
				dropDownTexts !== null
				?	<form class="search-form" onSubmit={handleSubmit}>
						<MainSearch
							dropDownTexts={dropDownTexts}
							searchOptions={searchOptions}/>
						<div class="search-more-button">
							<button
								class="search-button button-tertiary more text-link"
								type="button"
								onClick={() => setMoreButton(!moreButton)}
							>
								<span>weitere Merkmale</span>
								<div class={"fa " + (moreButton ? "fa-chevron-down" : "fa-chevron-right")}></div>
							</button>
						</div>
						<AdvancedSearch
							dropDownTexts={dropDownTexts}
							searchOptions={searchOptions}
							more={moreButton}/>
						<div class="search-buttons">
							<button class="search-button button-primary submit" type="submit">
								<div class="fa fa-search"></div>
								<span>Suchen</span>
							</button>
							<button
								onClick={() => actions.resetSearchOptions()}
								class="search-button button-secondary delete text-link"
								type="button">
								<span>Eingaben löschen</span>
							</button>
						</div>
					</form>
				:	<div>Derzeit steht leider keine Suchfunktion zur Verfügung.</div>
			}
			{ searchResults !== null &&
				<Results searchResults={searchResults}/>
			}
		</div>
	);
};
